import * as React from "react"
import Layout from "../components/Layout"
import Button from "../components/Button"
import { Link } from "gatsby"
import Logo from '../images/logo.svg'
import Seo from "../components/Seo";
import '../styles/index.css'

import { ChevronRightIcon, LinkIcon } from '@heroicons/react/outline'
import NyseLogoCloud from "../components/NyseLogoCloud"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FadeIn, FadeInStagger } from "../components/FadeIn"


const features = [
  {
    name: 'NYSE® OPEN Venture Capital Unicorn® Index',
    description:
      'The NYSE® OPEN Venture Capital Unicorn® Index is a modified market capitalization-weighted index designed to track the 50 largest U.S. venture capital backed private companies with valuations of $1 billion or greater.',
    href: '/unicorn-index',
    icon: ChevronRightIcon,
  },
  {
    name: 'Custom Indices - OPEN Pegasus',
    description:
      "Take advantage of OPEN's expertise to construct custom indices that meet your objectives.",
    href: '/custom-solutions',
    icon: ChevronRightIcon,
  },
]

const stats = [
  { id: 1, name: 'Unicorns in OPEN\'s universe', value: '1300+' },
  { id: 2, name: 'Private market value tracked', value: '$1.1 tn+' },
  { id: 3, name: 'Sectors covered', value: '12' },
]


const posts = [
  {
    id: 1,
    title: 'OPEN Unicorn® 50 Index Fund',
    imageUrl: 'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg',
    href: '#',
    description: 'The OPEN Unicorn® Index Fund (UIF) is a venture fund that tracks OPEN\'s overlying index of the Top 50 US-domiciled venture-backed private companies.',
  },
  {
    id: 3,
    title: 'OPEN Pegasus - Custom Investments',
    imageUrl: 'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg',
    href: '#',
    description: 'Invest like the best; Take advantage of OPEN\'s expertise to construct custom portfolios that meet your investment objectives.',
  },
  {
    id: 3,
    title: 'OPEN Unicorn® 50 Index',
    imageUrl: 'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg',
    href: '#',
    description: 'The OPEN Unicorn® Index is a capitalization-weighted index comprised of the largest 50 venture capital backed private companies domiciled in the United States.',
  },
]

const PostArticle = ({ post }) => (
  <article className="flex flex-col items-center justify-center">
    <div className="relative w-40 border-4 rounded-lg bg-white">
      <img src={post.imageUrl} alt="" className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
    </div>
    <div className="max-w-xl">
      <div className="group relative">
        <h3 className="mt-8 text-xl md:text-3xl leading-9 text-gray-900 group-hover:text-gray-600">
          <Link to={post.href}><span className="absolute inset-0" />{post.title}</Link>
        </h3>
        <p className="mt-5 text-md md:text-xl leading-7 text-gray-600">{post.description}</p>
      </div>
    </div>
  </article>
);

/* TODO grab companies logo from API and pull 5 images at a time and move index till end then repeat again OR save all locally*/
const logos = [
  ['/unicorn-index-logos/openai.svg', '/unicorn-index-logos/stripe.svg', '/unicorn-index-logos/spacex.svg', '/unicorn-index-logos/chime.svg'],
  ['/unicorn-index-logos/databricks.svg', '/unicorn-index-logos/discord.svg', '/unicorn-index-logos/fanatics.svg', '/unicorn-index-logos/anduril.svg',],
  ['/unicorn-index-logos/notion.svg', '/unicorn-index-logos/stripe.svg', '/unicorn-index-logos/spacex.svg', '/unicorn-index-logos/discord.svg',],
  ['/unicorn-index-logos/databricks.svg', '/unicorn-index-logos/openai.svg', '/unicorn-index-logos/fanatics.svg', '/unicorn-index-logos/spacex.svg'],
  ['/unicorn-index-logos/anduril.svg', '/unicorn-index-logos/spacex.svg', '/unicorn-index-logos/notion.svg', '/unicorn-index-logos/stripe.svg'],
];

const IndexPage = () => {
  return (
    <Layout transparentAtTop={true} hideFooter={false}>
      <Seo title="Home" description="Indexing and investment solutions for venture capital. OPEN is bringing the revolutionary idea of index investing and benchmarking to late-stage venture & growth. We provide data and access to some of the world's most sought after investments." />
      <div className="bg-gray-100 relative">
        <div className="mx-auto max-w-7xl flex h-[620px] md:pl-16 items-center bg-gray-100">
          <div className="max-w-2xl">
            <FadeIn>
              <div className="max-w-lg px-6 lg:px-0">
                <div className="invisible sm:visible relative  w-fit flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-green-900/10 bg-green-500/10">
                  <span className="relative inline-flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                  </span>
                  <span className="font-semibold text-gray-600">NYSE® OPEN Venture Capital Unicorn® Index</span>
                  <span className="h-4 w-px bg-green-900/10" aria-hidden="true" />
                  <span className="absolute inset-0" aria-hidden="true" />
                  <Link to="/unicorn-index" className="font-semibold text-green-900">
                    <span className="absolute inset-0" aria-hidden="true" />
                    View <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
                <h1 className="text-center sm:text-left mt-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Indexing and investment solutions for venture capital.
                </h1>

                <p className="text-center sm:text-left mt-6 text-xl leading-8 text-gray-600">
                  OPEN is bringing the revolutionary idea of index investing and benchmarking to late-stage venture & growth.
                  We provide data and access to some of the world's most sought after investments.
                </p>
                <div >
                  <AnchorLink to="/#solutions" stripHash={true}>
                    <Button classes='px-5 mt-12 w-full sm:w-fit'>View our solutions</Button>
                  </AnchorLink>
                </div>
              </div>
            </FadeIn>
          </div>
          <div className="absolute h-full right-slant w-full flex-1 py-10 bg-[url('../images/raster-bg-alone-2x.png')] bg-cover bg-primary">
            <div className="h-full flex flex-col justify-between overflow-hidden">
              {logos.map((row, rowIndex) => (
                <div key={rowIndex} className="flex row w-full overflow-hidden" style={{ "animationDelay": `-${rowIndex * 2}s` }}>
                  <div className="flex logos-set original 3xl:gap-24 gap-10 justify-between">
                    {row.map((logo, index) => (
                      <div key={`orig-${index}`} className="item">
                        <img src={logo} alt={`logo-${index}`} className="h-full object-contain mx-auto" />
                      </div>
                    ))}
                  </div>
                  <div className="flex logos-set duplicate 3xl:gap-24 gap-10 justify-between">
                    {row.map((logo, index) => (
                      <div key={`dup-${index}`} className="item">
                        <img src={logo} alt={`logo-${index}-dup`} className="h-full object-contain mx-auto" />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <FadeInStagger>
        <div className="bg-white py-16 sm:py-24" >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <FadeIn>
              <div className="mx-auto max-w-2xl lg:text-center" id="solutions">
                <h2 className="text-base font-semibold leading-7 text-primary">Our Solutions</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Empowering investment and insights in late-stage venture.
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Accessing and understanding the most innovative private companies is challenging and expensive. OPEN's solutions give you the ability to evaluate and invest with ease.
                </p>
              </div>
            </FadeIn>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                {features.map((feature) => (
                  <FadeIn key={feature.name}>
                    <div className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                        <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                        <p className="flex-auto">{feature.description}</p>
                        <p className="mt-6">
                          <Link to={feature.href} className="text-sm text-primary font-semibold leading-6 text-indigo-400">
                            Learn more <span aria-hidden="true">→</span>
                          </Link>
                        </p>
                      </dd>
                    </div>
                  </FadeIn>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </FadeInStagger>

      <FadeIn>
        <div className="bg-gray-50 py-16 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-primary">Our Mission</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Democratizing Access To Venture
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                OPEN's mission is to create industry benchmarks and investment solutions in private markets with a focus on late-stage venture capital and growth.</p>
            </div>
          </div>
        </div>
      </FadeIn>

      <FadeInStagger>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
              {stats.map((stat) => (
                <FadeIn key={stat.id}>
                  <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                    <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                      {stat.value}
                    </dd>
                  </div>
                </FadeIn>
              ))}
            </dl>
          </div>
        </div>
      </FadeInStagger>

      <FadeInStagger>
        <div className="bg-primary bg-cover bg-[url('../images/raster-bg-alone-2x.png')]">
          <div className="mx-auto max-w-7xl px-6 py-20 sm:py-24 lg:flex lg:items-center lg:justify-between lg:px-8">
            <FadeIn>
              <h2 className="text-white text-3xl font-bold tracking-tight sm:text-4xl">
                Stay updated:<br />
                Join our mailing list
              </h2>
            </FadeIn>
            <FadeIn>
              <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                <form name="mailing" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/lead-form-success" className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 w-full">
                  <input type="hidden" name="form-name" value="mailing" />
                  <input className="hidden" name="bot-field" />
                  <input type="hidden" id="gclid_field" name="gclid_field" value=""></input>
                  <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                    <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="email" name="email" required placeholder="Email" />
                  </div>
                  <div className="grid grid-cols-1 md:col-span-2 gap-6">
                    <Button classes='text-white px-3 sm:px-16'>Join Mailing List</Button>
                  </div>
                </form>
              </div>
            </FadeIn>
          </div>
        </div>
      </FadeInStagger>

    </Layout>
  )
}

export default IndexPage
